import React, { useState, useEffect } from "react";
import { m } from "framer-motion";
import useAnnouncementData from "@staticQueries/AnnouncementQuery";
import { Text, Icon, Container } from "@atoms";
import { AppLink } from "@base";

const Announcement = () => {
  const { enabled, heading, copy, button } = useAnnouncementData();
  const [show, setShowing] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      const seen =
        window.localStorage.getItem("announcement") === heading + copy;
      if (enabled && !seen) {
        setShowing(true);
      }
    }, 0);
  }, []);

  const variants = {
    show: {
      height: "auto",
      pointerEvents: "auto",
    },
    hide: {
      height: 0,
      pointerEvents: "none",
    },
  };

  return (
    <m.div
      initial="hide"
      variants={variants}
      animate={show ? "show" : "hide"}
      className="relative overflow-hidden bg-slate pr-4 text-white"
    >
      <Container padding className="relative">
        <div className="flex flex-col flex-wrap gap-1 py-3 sm:flex-row sm:items-center sm:justify-between sm:gap-3">
          <div className="flex flex-col gap-1">
            <Text
              tag="h6"
              className="font-sans text-sm font-bold leading-tighter"
            >
              {heading}
            </Text>
            {copy && (
              <Text className="text-xxs font-medium leading-tight">{copy}</Text>
            )}
          </div>
          {button?.url && (
            <AppLink
              className="flex-shrink-0 text-[16px] font-medium underline underline-offset-2 transition duration-100 hover:text-pink"
              to={button.url}
            >
              {button.text}
            </AppLink>
          )}
        </div>

        <button
          type="button"
          className="absolute bottom-0 right-1 top-0 flex items-center justify-center"
          onClick={() => {
            window.localStorage.setItem("announcement", heading + copy);
            setShowing(false);
          }}
        >
          <span className="sr-only">Close announcement</span>
          <Icon
            name="close"
            className="h-4 w-4 text-white opacity-50 transition duration-100 hover:opacity-100"
          />
        </button>
      </Container>
    </m.div>
  );
};

export default Announcement;
