import { useStaticQuery, graphql } from "gatsby";

const query = graphql`
  query AnnouncementQuery {
    craft {
      globalSet(handle: "announcement") {
        ... on Craft_announcement_GlobalSet {
          enabled: boolean0
          heading: heading0
          copy: descriptor0
          button: link0 {
            text
            url
          }
        }
      }
    }
  }
`;

const dataResolver = ({ globalSet }) => globalSet;

const useDefaultsData = () => {
  const { craft: data } = useStaticQuery(query);
  return dataResolver(data);
};

export default useDefaultsData;
